import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveClient({ commit }, client) {
      try {
        return await api.post('/clients', client)
      } catch (error) {
        throw error
      }
    },

    async getClients({ commit }, data) {
      try {
        let url = `/clients`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getClient({ commit }, clientId) {
      try {
        const response = await api.get(`/clients/${clientId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateClient({ commit }, client) {
      try {
        return await api.put(`/clients/${client.id}`, client)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

import api from '@/services/api'

export default {
  state: {
    user: {
      id: 0,
      name: '',
      modules: null,
    },
  },

  mutations: {},

  actions: {
    async getCartasSite({ commit }, data) {
      try {
        let url = `/cartas-site/${data}`

        if (data && data.valor) {
          url += `?valor=${data.valor}`
        }
        const response = await api.get(url)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSegmentosFiltro({ commit }, data) {
      try {
        let url = `/segmentos-filtro`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCartaInfoSite({ commit }, cartaId) {
      try {
        const response = await api.get(`/cartas-info-site/${cartaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

import auth from './modules/auth/auth'
import layouts from './modules/layout/layouts'
import users from './modules/user/users'
import modules from './modules/module/modules'
import commons from './modules/common/commons'
import clients from './modules/client/clients'
import projects from './modules/client/projects'
import cartas from './modules/cartas/cartas'
import administradoras from './modules/cartas/administradoras'
import segmentos from './modules/cartas/segmentos'
import cartasSite from './modules/cartas/cartasSite'

export default {
  auth,
  layouts,
  users,
  modules,
  commons,
  clients,
  projects,
  cartas,
  administradoras,
  segmentos,
  cartasSite,
}

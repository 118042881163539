const BASE_URL = '/'
const AUTH = BASE_URL
const PASSWORD_RECOVERY = `${BASE_URL}password-recovery`
const NEW_PASSWORD = `${BASE_URL}new-password/:key`
const HOME = `${BASE_URL}home`
const ADM = `${BASE_URL}administration`
const ADM_USERS = `${ADM}/users`
const BACKOFFICE = `${BASE_URL}backoffice`
const BACKOFFICE_CLIENTS = `${BACKOFFICE}/clients`
const BACKOFFICE_DETAILS_CLIENT = `${BACKOFFICE_CLIENTS}/:clientId`
const CARTAS = `${BACKOFFICE}/cartas`
const CARTAS_SITE = `${BASE_URL}cartas-site/:segmentos`
const ADMINISTRADORAS = `${BACKOFFICE}/administradoras`
const SEGMENTOS = `${BACKOFFICE}/segmentos`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  AUTH,
  PASSWORD_RECOVERY,
  NEW_PASSWORD,
  HOME,
  ADM,
  ADM_USERS,
  BACKOFFICE,
  BACKOFFICE_CLIENTS,
  BACKOFFICE_DETAILS_CLIENT,
  CARTAS,
  CARTAS_SITE,
  ADMINISTRADORAS,
  SEGMENTOS,
  UNAUTHORIZED,
}

export default {
  state: {
    layoutType: 'vertical',
    layoutWidth: 'fluid',
    topbarMode: 'dark',
    leftSidebarType: 'dark',
  },

  mutations: {
    CHANGE_LAYOUT(state, layoutType) {
      state.layoutType = layoutType
    },

    CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
      state.layoutWidth = layoutWidth
    },

    CHANGE_TOPBAR_MODE(state, topbarMode) {
      state.topbarMode = topbarMode
    },

    CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
      state.leftSidebarType = leftSidebarType
    },
  },

  actions: {
    changeLayoutType({ commit }, { layoutType }) {
      commit('CHANGE_LAYOUT', layoutType)
    },

    changeLayoutWidth({ commit }, { layoutWidth }) {
      commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
    },

    changeTopbarMode({ commit }, { topbarMode }) {
      commit('CHANGE_TOPBAR_MODE', topbarMode)
    },

    changeLeftSidebarType({ commit }, { leftSidebarType }) {
      commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
    },
  },

  getters: {
    layoutType: (state) => state.layoutType,
    layoutWidth: (state) => state.layoutWidth,
    topbarMode: (state) => state.topbarMode,
    leftSidebarType: (state) => state.leftSidebarType,
  },
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Auth from '../views/auth/Auth.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    name: 'PasswordRecovery',
    path: PATHS.PASSWORD_RECOVERY,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ '../views/auth/PasswordRecovery.vue'
      ),
  },
  {
    name: 'NewPassword',
    path: PATHS.NEW_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "newPassword" */ '../views/auth/NewPassword.vue'
      ),
  },
  {
    name: 'CartasSite',
    path: PATHS.CARTAS_SITE,
    component: () =>
      import(
        /* webpackChunkName: "cartasSite" */ '../views/cartasSite/CartasSite.vue'
      ),
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Administration',
    path: PATHS.ADM,
    component: () =>
      import(
        /* webpackChunkName: "administration" */ '../views/administration/Administration.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '0',
    },
    children: [
      {
        name: 'AdministrationUsers',
        path: PATHS.ADM_USERS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/users/Users.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
    ],
  },
  {
    name: 'BackOffice',
    path: PATHS.BACKOFFICE,
    component: () =>
      import(
        /* webpackChunkName: "backOffice" */ '../views/backoffice/BackOffice.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '1',
    },
    children: [
      {
        name: 'BackOfficeClients',
        path: PATHS.BACKOFFICE_CLIENTS,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/Clients.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'BackOfficeDetailsClient',
        path: PATHS.BACKOFFICE_DETAILS_CLIENT,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/ClientDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'Cartas',
        path: PATHS.CARTAS,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/cartas/Cartas.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.01.001',
        },
      },
      {
        name: 'Administradoras',
        path: PATHS.ADMINISTRADORAS,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/cartas/Administradoras.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.01.001',
        },
      },
      {
        name: 'Segmentos',
        path: PATHS.SEGMENTOS,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/cartas/Segmentos.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '4.01.001',
        },
      },
    ],
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && to.name === 'Auth') {
    next({ name: 'Home' })
  } else if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn && !localStorage.getItem('token')) {
      next({ name: 'Auth' })
    } else {
      if (to.meta.permission !== undefined) {
        if (!store.getters.getModuleUserByCode(to.meta.permission)) {
          next({ name: 'Unauthorized' })
        }
      }
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedIn && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})

export default router

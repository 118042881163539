import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveSegmento({ commit }, data) {
      try {
        return await api.post('/segmentos', data)
      } catch (error) {
        throw error
      }
    },

    async getSegmentos({ commit }, data) {
      try {
        let url = `/segmentos`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSegmento({ commit }, segmentoId) {
      try {
        const response = await api.get(`/segmentos/${segmentoId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateSegmento({ commit }, data) {
      try {
        return await api.put(`/segmentos/${data.id}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

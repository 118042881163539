import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveProject({ commit }, data) {
      try {
        return await api.post(`clients/${data.clientId}/projects`, data.project)
      } catch (error) {
        throw error
      }
    },

    async getProjects({ commit }, data) {
      try {
        let url = `/clients/${data.clientId}/projects`

        if (data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getProject({ commit }, data) {
      try {
        const response = await api.get(
          `/clients/${data.clientId}/projects/${data.projectId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateProject({ commit }, data) {
      try {
        return await api.put(
          `/clients/${data.clientId}/projects/${data.projectId}`,
          data.project,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

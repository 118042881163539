import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveCarta({ commit }, data) {
      try {
        return await api.post('/cartas', data)
      } catch (error) {
        throw error
      }
    },

    async getCarta({ commit }, cartaId) {
      try {
        const response = await api.get(`/cartas/${cartaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCartas({ commit }, data) {
      try {
        let url = `/cartas`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getParcelasByCarta({ commit }, cartaId) {
      try {
        const response = await api.get(`/cartas/${cartaId}/parcelas`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateCarta({ commit }, data) {
      try {
        return await api.put(`/cartas/${data.id}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
